import Keycloak from 'keycloak-js';

const authStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://dev.insights.meld.cx',
    peopleCounting: 'cd0179b4-2647-4adf-bde3-09cc3b4b81a0',
    posCorrelation: '76b5f473-f41c-4d1b-a2bc-1ab556627c70',
    productEngagement: 'f6a17375-a608-4db5-9ca6-f6829313140e',
    shelfEngagement: 'a6061beb-c172-4db1-b039-5a1d3beecc10',
    zoneEngagement: '2af6c747-0ac2-4337-b0ff-0fa237111ee2',
    trafficMeasurement: 'deee8fe6-d039-465a-8d86-ea2c218e08a4',
    aam: 'fd3b06fb-6315-41b7-89b5-0ba34477ead1',
    coffee: '46b7d2d7-ddf0-4d85-ad17-ba2691a304b4',
    samie: '7be37ce7-4dda-4e5a-98d4-401ef23718cc',
    liquorland: '75fd5a7f-065a-4e54-bbbb-aebe12bb808a',
    red: 'd23346f1-b42b-47c6-9447-d566d37b19cd',
    perdue: '5e751947-7b13-4a3a-b9fb-984437611775',
    sampl: 'b3bfc6c2-74e5-4ef1-965d-bc65eec7b543',
    sampp: '38dc75ba-f1b1-4ab5-afb9-23615460f8b4',
    samdt: '0090d2ed-7097-4db5-8a90-893dabce1c3c'
  },
  superset: {
    baseUrl: 'https://superset-staging.internal.viana.ai',
    trafficMeasurement: 'a54b1bd7-28c7-41c8-a2d2-eb1b9d736f9f',
    peopleCounting: 'e25e3117-d78f-4f06-8935-54131b9081b7',
    red: {
      red: '9c97fef0-0445-40ad-bc79-03303311988f',
      red_pg: '5977cb4d-21c2-447a-b891-b61527fba46e',
      red_classic: '9c97fef0-0445-40ad-bc79-03303311988f',
      red_basic: '6bdefc37-d62d-46a2-abbe-d6b99cff51dc',
      red_gamma: '9c97fef0-0445-40ad-bc79-03303311988f',
      red_zeta: '5977cb4d-21c2-447a-b891-b61527fba46e',
      red_sos: 'c1e13f65-b4bd-4322-8f6a-75dda5bd51c8',
    },
    aam: '224c1434-60e6-484f-bb61-90bb7276b64b',
    samdt: {
      samdt: '0fa6946f-99a9-4174-8c10-32c103e98ca0',
      samdt_meldcx: 'bdc22a39-ba0a-41fa-aaf4-b744b89f947d',
    },
    shelfEngagement: 'a6e93268-0917-4f0f-bb1e-c55a04ce7c20',
    partnerOps: 'c36756a4-b920-44c8-8443-46d3845baf1a'
  }
};

const endpointsStaging = {
  environment: 'staging',
  base_url: 'https://staging.services.api.viana.ai/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://0fff9f661ea047f683b1113a7fcc80bd@sentry.internal.viana.ai/8'
};

const endpointsStaging2 = {
  environment: 'staging2',
  base_url: 'https://viana-services-staging-copy2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging-stage.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsStaging4 = {
  environment: 'staging4',
  // base_url: 'https://viana-services-staging-copy3.azurewebsites.net/',
  // base_url: 'https://viana-services-n16-staging-svc-staging4.azurewebsites.net/',
  // base_url_insights: 'https://viana-insights-n16-staging-svc-copy4.azurewebsites.net/',
  // base_url_insights: 'https://viana-insights-staging-auspost.azurewebsites.net/',
  base_url: 'https://viana-services-n16-staging-svc-staging4.azurewebsites.net/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsVersaDev = {
  environment: 'versa_dev',
  base_url: 'https://versa-services-dev-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-dev-svc.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVersaStaging = {
  environment: 'versa_staging',
  base_url: 'https://versa-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-staging-svc.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVianaLite = {
  environment: 'viana_lite_internal',
  base_url: 'https://viana-internal-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVianaLitePreproduction = {
  environment: 'viana_lite_internal_preproduction',
  base_url: 'https://viana-internal-services-ppd-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  admin_url: 'https://prep.admin.internal.viana.ai/'
};

const authDev = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://dev.insights.meld.cx',
    peopleCounting: 'c0786c24-963d-4e22-a3c3-8b4737aedabb',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    stories: 'e0588ff4-e139-4559-b3a7-e3d980e4c7b9',
    trafficMeasurement: 'e89b0747-4cba-48f6-a009-da3e0634224d',
    aam: '6e1babb3-4e7f-4f70-9754-78f00cd90f7e',
    coffee: 'e696956a-457f-407b-bd78-2e5c73495504',
    samie: '1488a68c-d08f-495a-873d-b2417233a9e8',
    red: '2324ab1c-c079-411e-8d2f-5ea94e3c363f',
    perdue: '5bec409a-0823-4aea-b018-d48f1a0e9823',
    sampl: '',
    sampp: 'a4b67381-b6bf-4b50-9b74-22dba86b504d',
    samdt: '79986eee-c385-4fcd-bec3-d12482865e85'
  },
  superset: {
    baseUrl: 'https://superset-dev.internal.viana.ai',
    trafficMeasurement: '344fea4a-b496-474c-a21d-ba54fed81c7b',
    red: '1fa0b4cd-7179-463f-b8fc-05f3133bb8fe',
    samli: '5d57aa6d-7a6a-4859-b58a-8090203fe5b2',
    samdt: '9fab0aaa-d6a9-406d-b54e-07a1e50b2e75',
    aam: '9838532f-38f7-458e-91b6-a4f3dbdc32d9',
    shelfEngagement: 'd71f6575-89ef-4ee6-8d76-45fe99abe382'
  }
};

const endpointsDev = {
  environment: 'dev',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'https://dev.insights.api.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  // sentry_dsn: 'https://3c84de2cf4ef48c8b81d9959fa173ad3@sentry.internal.viana.ai/7'
};

const endpointsNode16Dev = {
  environment: 'node16_dev',
  base_url: 'https://services.node16poc.internal.viana.ai/',
  base_url_insights: 'https://insights.node16poc.internal.viana.ai/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsDev2 = {
  environment: 'dev2',
  base_url:'https://dev2.services.api.viana.ai/',
  base_url_insights: 'https://dev2.insights.api.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsDev3 = {
  environment: 'dev3',
  base_url: 'https://viana-dev-copy3.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev-copy2.azurewebsites.net/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsDev4 = {
  environment: 'dev4',
  base_url: 'https://viana-dev-copy4.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsPerduePreproduction = {
  environment: 'perdue_ppd',
  base_url: 'https://viana-services-preprod-2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-perdue.azurewebsites.net/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://87eb9ff084514f84984d79fc2ceb39c2@sentry.internal.viana.ai/9'
};

const authPreproduction = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user',
  powerBIReportID: 'cd127ee8-e16b-4f83-8fae-2d096d07ab77',
  powerBIGroupID: '1aa87852-3472-4e22-b260-796f0e301f33',
  superset: {
    baseUrl: 'https://superset-ppd.internal.viana.ai',
    red: {
      red: 'b4dc7fe1-ffff-432a-98cb-0384845ef051',
      red_pg: 'b4dc7fe1-ffff-432a-98cb-0384845ef051',
      red_classic: '440facd3-4db8-47c3-9fc7-94f802c567a4',
      red_basic: '80cdacce-05bb-4433-aa1b-13efccf1dba8',
      red_gamma: 'b4dc7fe1-ffff-432a-98cb-0384845ef051',
      red_zeta: 'b4dc7fe1-ffff-432a-98cb-0384845ef051',
      red_sos: 'b4641746-d612-40c1-b88d-e3ae68f0c32d',
    },
    peopleCounting: '366e139b-418e-445e-9362-e5efc8998028',
    trafficMeasurement: '0ab91b9d-c18b-472b-b4be-6c2400ef9a85',
    aam: {
      default: '533ae3a3-55ee-464d-8197-baa308d9fdb8',
      bs: '4e907500-bda3-47b2-bccd-75a772b9326d',
      meldcx: '533ae3a3-55ee-464d-8197-baa308d9fdb8',
    },
    samli: 'f401eee8-e4f0-4071-9420-1f41081cf4ce',
    samdt: {
      default: '305bd351-f335-46dd-bc4c-ae6ddc95cc19',
      meldcx: 'ee4bebbf-bf1b-4c36-b183-4e88bdd861b1',
      sb: 'ee4bebbf-bf1b-4c36-b183-4e88bdd861b1',
    },
    partnerOps: 'aceea188-7c56-47a8-bb61-2866038cb40c',
  }
};

const endpointsPreproduction = {
  environment: 'preproduction',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://ppd.insights.api.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://ddb5c9f286f842168168f35734d27f3c@sentry.internal.viana.ai/10'
};



const endpointsNespressoPreproduction = {
  environment: 'nespresso_ppd',
  base_url: 'https://viana-services-preprod-2-nespresso.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-nespresso.azurewebsites.net/',
  admin_url: 'https://nespresso.prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://ddb5c9f286f842168168f35734d27f3c@sentry.internal.viana.ai/10'
};

const authProduction = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
  powerBIReportID: 'f105c699-f9b4-49db-8c81-0b31179d8429',
  powerBIGroupID: '75816e02-3bb5-479b-9b13-c5232ba9bbe1',
  superset: {
    baseUrl: 'https://cortex2.viana.ai',
    samdt: {
      samdt: '480e4563-ea4a-4e49-80a6-3ad37b9b8371',
      samdt_meldcx: '5cf52fb0-6f50-43b0-a1db-60660b5e9505',
      pg: '63894c31-907d-432d-9943-9d97bde61307',
      starbucksph: '2b18fbf7-1153-4776-be3a-5443e7aceba2',
    },
    red: {
      red: '0c58aa37-398f-4dee-a0e3-3ea3ad4cc743',
      red_pg: '5f1b0d77-0040-4582-aa74-ca9b7a71c511',
      red_classic: '0c58aa37-398f-4dee-a0e3-3ea3ad4cc743',
      red_basic: '6902b044-2072-4a37-968d-6f3606984910',
      red_gamma: '8e8db710-2734-438e-a0cb-f1585638e9f9',
      red_zeta: '8e8db710-2734-438e-a0cb-f1585638e9f9',
      red_sos: '3f25386e-6860-4991-ab9b-380b9fde9bc2',
    },
    peopleCounting: '5ac1d70a-bb96-4c38-b0e6-29b8e3f94be8',
    trafficMeasurement: '3a55a582-1373-449c-b2f7-f42315726363',
    aam: {
      default: '0528d3c5-28f9-47d2-bd14-8467dc0cbdbd',
      meldcx: 'e97dbbf6-953a-445f-9ed0-468ae32aad5b',
      aircads: 'ca47e984-474e-4f43-9843-b87f16d377bc',
    },
    samli: 'ba4945b6-9885-4eb4-8864-f67b05281ef6',
    partnerOps: {
      default: 'ab71c534-44e4-4eb9-b680-ab1d58cb76f4',
      starbucksph: 'e4b2cc69-00c8-420e-8408-60039f15b42f',
      pg: 'c02d5f3c-b73b-4008-aa5c-5533a00cc9ea',
      meldcx: 'fd9796e2-2025-4b97-9184-619293a1488d',
    },
  }
};

const endpointsProduction = {
  environment: 'production',
  base_url: 'https://services.api.viana.ai/',
  //base_url: 'https://viana-services-n16-production.azurewebsites.net/',
  base_url_insights: 'https://insights.api.viana.ai/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: ''
};

const endpointsProd2 = {
  environment: 'prod2',
  base_url: 'https://viana-services-production-prod2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-production2-prod2.azurewebsites.net/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://ef2e4e96c0b043fe905a8a932eefbddf@sentry.internal.viana.ai/20'
};

const authOptus = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
  powerBIReportID: 'fd4a155b-f344-439f-9880-2ec21f6358aa',
  powerBIGroupID: 'a9b83148-febd-4493-a7d3-8d6e04b80180',
  yellowfin: {
    baseUrl: 'https://apac-poc.yellowfin.bi',
    peopleCounting: 'c41091eb-58b0-4628-b736-53e30c03b7c8',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    trafficMeasurement: '88dd3c49-62c5-4bcd-aff2-1c0bcf808500',
    aam: 'b0dc435c-870f-4b9e-b2d3-1ba409435769',
    coffee: '72297534-7197-4390-b4d1-61eba51ddb36',
    samie: '9d458d9d-76c3-417e-97f3-248b99bc6d0b',
  }
};

const endpointsOptus = {
  environment: 'optus',
  base_url: 'https://optus.services.api.viana.ai/',
  base_url_insights: 'https://optus.insights.api.viana.ai/',
  admin_url: 'https://optus.admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const authPCStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://apac-poc.yellowfin.bi',
    peopleCounting: 'c41091eb-58b0-4628-b736-53e30c03b7c8',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    trafficMeasurement: '33932758-58d5-4b27-942d-9b910a928874',
    aam: '6e1babb3-4e7f-4f70-9754-78f00cd90f7e',
    coffee: '72297534-7197-4390-b4d1-61eba51ddb36',
    samie: '9d458d9d-76c3-417e-97f3-248b99bc6d0b',
  }
};

const endpointsPCStaging = {
  environment: 'pc-staging',
  base_url: 'https://viana-dev.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsLocal = {
  environment: 'local',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'http://localhost:4000/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
};

export let auth;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  auth = authProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'perdue_ppd') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'pc-staging' || process.env.REACT_APP_VIANA_ENV === 'pc-local') {
  auth = authPCStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'optus' || process.env.REACT_APP_VIANA_ENV === 'optus-local') {
  auth = authOptus;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev4') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging4') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  auth = authPreproduction;
}
else if(process.env.REACT_APP_VIANA_ENV === 'prod2') {
  auth = authProduction;
}
else {
  auth = authStaging;
}

export let endpoints;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  endpoints = endpointsProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  endpoints = endpointsPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'perdue_ppd') {
  endpoints = endpointsPerduePreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  endpoints = endpointsNespressoPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'pc-staging' || process.env.REACT_APP_VIANA_ENV === 'pc-local') {
  endpoints = endpointsPCStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'optus' || process.env.REACT_APP_VIANA_ENV === 'optus-local') {
  endpoints = endpointsOptus;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  endpoints = endpointsDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  endpoints = endpointsDev2;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev3') {
  endpoints = endpointsDev3;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev4') {
  endpoints = endpointsDev4;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  endpoints = endpointsNode16Dev;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  endpoints = endpointsStaging2;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging4') {
  endpoints = endpointsStaging4;
} else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  endpoints = endpointsVersaDev;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  endpoints = endpointsVersaStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  endpoints = endpointsVianaLite;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  endpoints = endpointsVianaLitePreproduction;
}
else if(process.env.REACT_APP_VIANA_ENV === 'prod2') {
  endpoints = endpointsProd2;
}
else if(process.env.REACT_APP_VIANA_ENV === 'local') {
  endpoints = endpointsLocal;
}
else {
  endpoints = endpointsStaging;
}


const initOptions = {
  url: auth.auth_url, realm: auth.realm, clientId: auth.client_id, onLoad: 'login-required'
};

const keycloak = new Keycloak(initOptions);
export default keycloak;
